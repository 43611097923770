import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { AllHtmlEntities as entities } from "html-entities"

import Layout from "../../components/layout.js"
import Header from "../../components/header.js"
import SEO from "../../components/seo.js"
import JocJSONLD from "./json-ld.js"

import PlayersIcon from "../../assets/icons/players.svg"
import DurationIcon from "../../assets/icons/duration.svg"
import AgeIcon from "../../assets/icons/age.svg"
import StarIcon from "../../assets/icons/star.svg"
import StarOutlineIcon from "../../assets/icons/star-outline.svg"

const SingleJoc = ({ data }) => {
  const post = data.wordpressWpJocs
  post.title = entities.decode(post.title)
  return (
    <Layout>
      <Header className="text-white" />
      <BackgroundImage
        Tag="header"
        fluid={post.featured_media.localFile.childImageSharp.fluid}
      >
        <div className="joc-hero relative h-50vh flex flex-col justify-end py-4 md:py-8">
          <div className="container z-20 text-white">
            <h1 className="font-bold text-3xl md:text-6xl leading-tight">
              {post.title}
            </h1>
            <ul className="flex flex-wrap -mx-2 text-gray-300">
              {post.acf.jugadors && (
                <li className="flex items-center px-2">
                  <PlayersIcon className="mr-2 fill-current h-8 w-8" />{" "}
                  {post.acf.jugadors}
                </li>
              )}
              {post.acf.durada && (
                <li className="flex items-center px-2">
                  <DurationIcon className="mr-2 fill-current h-8 w-8" />{" "}
                  {post.acf.durada}
                </li>
              )}
              {post.acf.edat && (
                <li className="flex items-center px-2">
                  <AgeIcon className="mr-2 fill-current h-8 w-8" />{" "}
                  {post.acf.edat}
                </li>
              )}
            </ul>
          </div>
        </div>
      </BackgroundImage>
      <section className="py-12">
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <article className="w-full lg:w-3/5 px-4 order-2 lg:order-1">
              {post.content && (
                <>
                  <h2 className="mb-4 font-medium text-2xl text-orange-700">
                    Sinopsi de {post.title} en català
                  </h2>
                  <div
                    className="mb-12 text-lg leading-relaxed"
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  />
                </>
              )}
              <div className="mb-12 border border-orange-400 bg-orange-100 rounded p-8">
                <h2 className="mb-4 font-medium text-2xl text-orange-700">
                  Fitxa de {post.title} en català
                </h2>
                <ul className="md:col-count-2 md:col-gap-md">
                  {post.acf.autor && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Autor
                      </h3>
                      {post.acf.autor}
                    </li>
                  )}
                  {post.acf.editorial && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Editorial
                      </h3>
                      {post.acf.editorial}
                    </li>
                  )}
                  {post.acf.publicacio && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Any de publicació
                      </h3>
                      {post.acf.publicacio}
                    </li>
                  )}
                  {post.acf.premis && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Premis
                      </h3>
                      {post.acf.premis}
                    </li>
                  )}
                  {post.acf.jugadors && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Jugadors
                      </h3>
                      {post.acf.jugadors}
                    </li>
                  )}
                  {post.acf.durada && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Durada
                      </h3>
                      {post.acf.durada}
                    </li>
                  )}
                  {post.acf.edat && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Edat
                      </h3>
                      {post.acf.edat}
                    </li>
                  )}
                  {post.bgg_ranking && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Rànquing <abbr title="BoardGameGeek">BGG</abbr>
                      </h3>
                      {post.bgg_ranking}
                    </li>
                  )}
                  {post.bgg_rating && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Valoració <abbr title="BoardGameGeek">BGG</abbr>
                      </h3>
                      {parseFloat(post.bgg_rating).toFixed(2)}
                    </li>
                  )}
                  {/*post.acf.boardgamegeek_id && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Rànquing <abbr title="BoardGameGeek">BGG</abbr>
                      </h3>
                      {post.acf.boardgamegeek_id}
                    </li>
                  )
                  camps:
                  - rànquing bgg
                  - valoració bgg
                  - preu
                  */}
                </ul>
              </div>
              {post.acf.amazon_link && (
                <a
                  className="btn btn-buy table mx-auto lg:hidden"
                  href={post.acf.amazon_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="font-medium">Compra'l!</span>
                  {/* - 12.34€ */}
                </a>
              )}
            </article>
            <aside className="w-full lg:w-2/5 mb-8 lg:mb-0 px-4 order-1 lg:order-2">
              <figure className="mb-4 text-center">
                <Img fixed={post.acf.caixa.localFile.childImageSharp.fixed} />
              </figure>
              {post.acf.amazon_link && (
                <>
                  <h2 className="mb-4 font-medium text-xl text-orange-700 text-center">
                    Comprar {post.title} en català
                  </h2>
                  <a
                    className="btn btn-buy table mx-auto mb-8"
                    href={post.acf.amazon_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="font-medium">Compra'l!</span>
                    {/* - 12.34€ */}
                  </a>
                </>
              )}
              <div className="text-center">
                <h2 className="mb-4 font-medium text-2xl text-orange-700">
                  Valoració jocsdetaula.club
                </h2>
                <ul>
                  {post.acf.valoracio.atractiu && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Atractiu
                      </h3>
                      <div className="flex items-center justify-center -mr-2">
                        {[...Array(parseInt(post.acf.valoracio.atractiu))].map(
                          (e, i) => (
                            <StarIcon
                              key={i}
                              className="h-6 w-6 mr-1 fill-current text-orange-900"
                            />
                          )
                        )}
                        {[
                          ...Array(5 - parseInt(post.acf.valoracio.atractiu)),
                        ].map((e, i) => (
                          <StarOutlineIcon
                            key={i}
                            className="h-6 w-6 mr-1 fill-current text-orange-900"
                          />
                        ))}
                      </div>
                    </li>
                  )}
                  {post.acf.valoracio.rejugabilitat && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Rejugabilitat
                      </h3>
                      <div className="flex items-center justify-center -mr-2">
                        {[
                          ...Array(parseInt(post.acf.valoracio.rejugabilitat)),
                        ].map((e, i) => (
                          <StarIcon
                            key={i}
                            className="h-6 w-6 mr-1 fill-current text-orange-900"
                          />
                        ))}
                        {[
                          ...Array(
                            5 - parseInt(post.acf.valoracio.rejugabilitat)
                          ),
                        ].map((e, i) => (
                          <StarOutlineIcon
                            key={i}
                            className="h-6 w-6 mr-1 fill-current text-orange-900"
                          />
                        ))}
                      </div>
                    </li>
                  )}
                  {post.acf.valoracio.qualitat && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Qualitat
                      </h3>
                      <div className="flex items-center justify-center -mr-2">
                        {[...Array(parseInt(post.acf.valoracio.qualitat))].map(
                          (e, i) => (
                            <StarIcon
                              key={i}
                              className="h-6 w-6 mr-1 fill-current text-orange-900"
                            />
                          )
                        )}
                        {[
                          ...Array(5 - parseInt(post.acf.valoracio.qualitat)),
                        ].map((e, i) => (
                          <StarOutlineIcon
                            key={i}
                            className="h-6 w-6 mr-1 fill-current text-orange-900"
                          />
                        ))}
                      </div>
                    </li>
                  )}
                  {post.acf.valoracio.interaccio && (
                    <li className="mb-4">
                      <h3 className="uppercase text-sm text-orange-900">
                        Interacció
                      </h3>
                      <div className="flex items-center justify-center -mr-2">
                        {[
                          ...Array(parseInt(post.acf.valoracio.interaccio)),
                        ].map((e, i) => (
                          <StarIcon
                            key={i}
                            className="h-6 w-6 mr-1 fill-current text-orange-900"
                          />
                        ))}
                        {[
                          ...Array(5 - parseInt(post.acf.valoracio.interaccio)),
                        ].map((e, i) => (
                          <StarOutlineIcon
                            key={i}
                            className="h-6 w-6 mr-1 fill-current text-orange-900"
                          />
                        ))}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </section>
      <JocJSONLD post={post} />
      <SEO
        title={post.yoast_meta.yoast_wpseo_title}
        description={post.yoast_meta.yoast_wpseo_metadesc}
        image={post.featured_media.localFile.childImageSharp.fluid.src}
      />
    </Layout>
  )
}

export default SingleJoc

export const query = graphql`
  query($slug: String!) {
    wordpressWpJocs(slug: { eq: $slug }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1500) {
              src
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        caixa {
          localFile {
            childImageSharp {
              fixed(width: 288, height: 288) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
        autor
        editorial
        jugadors
        durada
        edat
        publicacio
        premis
        valoracio {
          atractiu
          rejugabilitat
          qualitat
          interaccio
        }
        boardgamegeek_id
        amazon_link
        amazon_sku
      }
      bgg_rating
      bgg_ranking
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
