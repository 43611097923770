import React from "react"
import moment from "moment"
import Helmet from "react-helmet"

const JocJSONLD = ({ post }) => {
  let schema = {
    "@context": "http://schema.org",
    "@type": "Game",
    name: post.title,
  }
  if (post.acf.autor) {
    schema["author"] = {
      "@type": "Person",
      name: post.acf.autor,
    }
  }
  if (post.acf.editorial) {
    schema["publisher"] = {
      "@type": "Organization",
      name: post.acf.editorial,
    }
  }
  if (post.acf.publicacio) {
    schema["copyrightYear"] = parseInt(post.acf.publicacio)
  }
  if (post.acf.premis) {
    schema["awards"] = parseInt(post.acf.premis)
  }
  if (post.acf.jugadors) {
    const minPlayers = post.acf.jugadors.split(/-|\+/)
    schema["numberOfPlayers"] = {
      "@type": "QuantitativeValue",
      minValue: parseInt(minPlayers[0]),
    }
    const maxPlayers = post.acf.jugadors.split(/-/)
    if (maxPlayers.length > 1) {
      schema["numberOfPlayers"]["maxValue"] = parseInt(maxPlayers[1])
    }
  }
  if (post.acf.durada) {
    schema["timeRequired"] = moment
      .duration(parseInt(post.acf.durada), "minutes")
      .toISOString()
  }
  if (post.acf.edat) {
    schema["audience"] = {
      "@type": "PeopleAudience",
      suggestedMinAge: parseInt(post.acf.edat),
    }
  }
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

export default JocJSONLD
